.titleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.mediumMargin {
    margin-top: 30px;
}
.actionBtn {
    display: flex;
    padding-top: 30px;
    flex-direction: row;
}

a.exportCsvBtn {
    background-color: #009400 !important;
    border-radius: 5px;
    color: #fff;
    margin-top: 20px!important;
    padding: 10px;
    text-decoration: none;
}

.exportExcelBtn {
    background-color: #009400;
    border-radius: 5px;
    color: #fff;
    margin-left: 25px!important;
    padding: 10px;
    cursor: pointer;
}