.navbarContainer{
    display: flex;
    flex: 1;
    background-color: #009400;
    min-height: 75px;
    max-height: 75px;
    width: 100%;
    flex-direction: row;
    position: fixed;
}

.leftSection {
    display: flex;
    flex: 0.6;
}

img.logo {
    width: 240px;
    height: 75px;
}

.middleSection {
    display: flex;
    flex: 1.1;
    align-items: center;
    justify-content: center;
}

button.menuBtn {
    color: #009400;;
    border-color: #F6C10F;
    background-color: #f8f9f9;
    height: fit-content;
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
}
 
.rightMiddleSection {
    display: flex;
    flex: 1.5;
    justify-content: flex-end;
    padding-right: 20px;
  }


.fstColumn {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.badgeCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 20px;
    justify-content: center;
}

.titleCol {
    display: flex;
    align-items: center;
    flex: 1.5;
    text-align: center;
}

a.titleColLink {
    color: white;
}

.sndColumn {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.subCol {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    justify-content: center;
}


.css-jcn4dz-MuiBadge-badge{
    background-color: red !important;
}

.low-badge {
    background-color: #dba700;
    /* font-size: 0.60rem !important;
    min-width: 17px !important;
    height: 20px !important; */
    color: white;
}

.medium-badge {
    background-color: #1976D2;
    /* font-size: 0.60rem !important;
    min-width: 17px !important;
    height: 20px !important; */
    color: white;
}

.high-badge {
    background-color: red;
    /* font-size: 0.60rem !important;
    min-width: 17px !important;
    height: 20px !important; */
    color: white;
}

.rightSection {
    display: flex;
    flex: 0.2;
    justify-content: flex-end;
    padding-right: 20px;
  }

  button.logoutBtn {
    border: 1.5px solid black;
    border-radius: 5px;
    background-color: #F6C10F;
}