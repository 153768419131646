.modalOverlay {
    width: 100%;
    height: 89vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
    position: absolute;
    z-index: 1;
  }
  
  .modalContainer {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
  }
  
  .modalContainerViewSchool {
    background-color: #fff;
    border-radius: 5px;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
    
  .css-yad0re-MuiContainer-root {
   
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .css-1170vc-MuiGrid-root {
   
    margin-top: 0px !important;
   
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
  }
  
  .modalContentViewSchool {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
   
  }

  ul.tabsTitle {
    display: flex;
    margin-top: -115px;
}

div#panel\:r1\:1 {
  display: flex;
  flex-direction: column;
}