.container{
  display: flex;
  flex-direction: column;
}

main.mainBody {
  margin-top: 75px;
  z-index: 1;
}

.modalOverlay {
  width: 100%;
  height: 89vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  position: absolute;
  z-index: 1;
}

.modalContainer {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}

.modalContainerViewSchool {
  background-color: #fff;
  border-radius: 5px;
  margin-left: 30px !important;
  margin-right: 30px !important;
}
  
.css-yad0re-MuiContainer-root {
 
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1170vc-MuiGrid-root {
 
  margin-top: 0px !important;
 
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.modalContentViewSchool {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
 
}
  
.title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.columnsContainer {
  display: flex;
  padding-bottom: 30px;
}


.column1 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.column2 {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.btns {
  display: flex;
  flex: 1;
}

.loadBtnContainer {
  display: flex;
  flex: 1;
}

button.loadBtn {
  background-color: #009400;
  border-color: #009400;
  color: white;
}

/* .viewSchools {
  height: 79vh;
} */

.closeModalBtnContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-top: 15px;
  padding-bottom: 15px;
}

.closeModalBtn {
  background-color: firebrick;
  border-color: firebrick;
  color: white;
}

/* .css-9fjqjw {
  background-color: transparent !important;
} */

/* ----------------------Table--------------------- */

/* .filter{
  padding-bottom: 20px !important;
  
 } */

 .row {
  display: flex;
}

.column1 h5{
  
  width: 150px;
}

.column2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column3 h5{
  width: 101px;
  
}

.column3{
  padding-left: 20px ;
}

.column4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  
}

.tableContainer {
  display: flex;
  flex-direction: column;
  margin-top: -45px;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse; 
  
}


 table td, table th {
  
  border-bottom: 1px solid rgb(105, 105, 105 )!important;
  padding: 8px;
} 

  table tr:nth-child(even){
  background-color: #c7c7c7 !important; 
  color: #000000;
} 

/* table tr:hover {background-color: #ffffff !important; }  */

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center !important;
  background-color: #009400 !important;
  color: rgb(255, 255, 255) !important;
}

td{
text-align: center;
}

th.orderNoWidth {
width: 200px;
}

th.dateTimeWidth {
width: 130px;
}

th.delAddNoWidth {
  width: 130px;
}

tbody {
  font-size: 12px;
}



 /*----------PAGINATION----------*/
 /* .paginationBttns{
  width: 84%;
  height: 20px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
 }

 .paginationBttns a{
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  cursor: pointer;

 }

 .paginationBttns a:hover{
  color: white;
  background-color: firebrick;
  color: inherit;
 } */

 .paginationActive a{
  color: white;
  background-color: firebrick;
  
 }

 a:focus {
  color: inherit !important;
}

.pagination {
  display: flex;
  /* margin-left: 200px !important; */
  list-style: none;
  align-items: center;
}

.pagination__link {
  margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.pagination__link--disabled {
  color: #ccc;
  cursor: default;
}

.pagination__link--active {
  background-color: #009400;
  color: #000000;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 2px;
  padding-left: 2px;
}

select.pageRows{
  font-size: small !important;
  width: 4% !important;
  height: 1.4rem !important;
  margin: 0 !important;
}

.rows-per-page {
  flex-direction: row;
  display: flex;
  margin-top: 15px !important;
}

label {
  padding-right: 25px !important;
}

